import styled from "styled-components";

export const StatsWrapper = styled.div`
    margin-top:20px;
    padding:40px;
`

export const StatsTitle = styled.div`
    color:white;
    font-family:Bailey;
    font-size:22px;
    text-align:center;
`
export const DayTitle = styled.div`
    color:#F8DF45;
    font-family:Bailey;
    font-size:18px;
    text-align:center;
    margin-top:30px;
`

export const StatGrid = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    color:white;
    text-align:center;
    p{
        line-height:30px;
    }
`