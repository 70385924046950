import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { ErrorTitle, ErrorMessage, ErrorCharacter } from "./styled";
import Whoops from '../../assets/images/whoops.png';
import Fooling from '../../assets/images/fooling.png';
import MarkGlasses from '../../assets/images/mark_glasses.png';
import Empty from '../../assets/images/empty.png';
import MissedIt from '../../assets/images/just_missed_it.png';
import MarkMiss from '../../assets/images/mark_miss.png';

const Error = () =>{

    let location = useLocation();
    const [errorType, setErrorType] = useState(0);

    useEffect(() => {
        if(location.state){
            setErrorType(location.state.type);
        }
    }, [location]);

    return(
        <div>
            <ErrorTitle>
            {errorType === 3 &&
                <img src={Whoops} alt="whoops" width={275}/>
            }
            {errorType === 2 &&
                <img src={MissedIt} alt="our bad" width={275}/>
            }
            {errorType === 1 &&
                <img src={Fooling} alt="who do you think you are fooling" width={275}/>
            }
            </ErrorTitle>
            <ErrorMessage>
            {errorType === 3 &&
                "SORRY, IT LOOKS LIKE YOU'RE OUT OF THE VALID GAME AREA."
            }
            {errorType === 2 &&
                "SORRY, WE ARE OUT OF PRIZES FOR THE DAY. TRY AGAIN TOMORROW."
            }
            {errorType === 1 &&
                "SORRY, BUT YOU ALREADY SPUN THE WHEEL."
            }
            </ErrorMessage>
            <ErrorCharacter>
                {errorType === 3 &&
                    <img src={MarkGlasses} alt="Mark with glasses" className='mark_glasses'/>
                }
                {errorType === 2 &&
                    <img src={MarkMiss} alt="Happy Mark" className="mark_miss"/>
                }
                {errorType === 1 &&
                    <img src={MarkGlasses} alt="Mark with glasses" className="mark_glasses"/>
                }
                {errorType === 0 &&
                    <img src={Empty} alt="Wait for it.." className="mernft"/>
                }
            </ErrorCharacter>
        </div>
    )
}

export default Error;