import styled from "styled-components";

export const LeftCloudWrapper = styled.div`
    position:absolute;
    top:0;
    left:0;
`
export const PageWrapper = styled.div`
    position:relative;
`

export const RightCloudWrapper = styled.div`
    position:absolute;
    top:0;
    right:0;
`

export const MainLogoWrapper = styled.div`
    text-align:center;
    padding-top:30px;
    img{
    width:240px;
}
`

export const Footer = styled.div`
    padding: 40px 20px;
    background-color:#F8DF45;
    margin-top: ${(props) => (props.nomargin ? '0' : '100px')};
    border:2px solid #2C2A28;
`

export const FoxWrapper = styled.div`
    display:flex;
    justify-content:center;
    img{
        width:60px;
    }
`

export const SocialMediaWrapper = styled.div`
    display:flex;
    justify-content:center;
    gap:10px;
    margin-top:30px;
    img{
        width:24px;
        height:24px;
    }
`

export const TandC = styled.div`
    margin-top:30px;
    text-align:center;
    a{
        font-family:Arial;
        font-size:16px;
        text-align:center;
        color:black;
        text-decoration:none !important;
        display:flex;
        justify-content:center;
        &:nth-child(1){
            margin-bottom:10px;
        }
    }
        p{
         font-family:Arial;
        font-size:16px;
        text-align:center;
        color:black;
        text-decoration:none !important;
        display:flex;
        justify-content:center;
        }
`

export const Legal = styled.div`
    font-family:Arial;
    font-size:8px;
    a{
        text-decoration:none !important;
        color:black;
    }
`