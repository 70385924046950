import styled from "styled-components";

export const ErrorTitle = styled.div`
    display:flex;
    justify-content:center;
    margin-top:30px;
`
export const ErrorMessage = styled.div`
    font-family:Bailey;
    font-size:18px;
    line-height:22px;
    color:white;
    font-weight:bold;
    text-align:center;
    margin-top:20px;
    max-width:280px;
    display:flex;
    margin-left:auto;
    margin-right:auto;
`

export const ErrorCharacter = styled.div`
    display:flex;
    margin-top:${(props) => props.smallpadding ? '2px' : '60px'};
    justify-content:center;
`