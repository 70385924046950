import React, { useState, useEffect } from "react";
import ThanksBud from "../../assets/images/thanks_bud.png";
import { ThanksCopy, ThanksCharacterWrap, ThanksTitleWrap } from "./styled";
import MarkHank from '../../assets/images/mark_and_hank.png';

const Thanks = () => {
    const [countdown, setCountdown] = useState(10);

    useEffect(() => {
      const interval = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown <= 1) {
            clearInterval(interval);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    
        const timer = setTimeout(() => {
          window.location.href = 'https://www.ubgfox.com/';
        }, 10000); // 10 seconds
    
        return () => {
          clearInterval(interval);
          clearTimeout(timer);
        };
      }, []);

  return (
    <>
      <ThanksTitleWrap>
        <img src={ThanksBud} alt="Thank You Bud" />
      </ThanksTitleWrap>
      <ThanksCopy>
        IN A FEW MOMENTS, YOU WILL RECIEVE AN EMAIL WITH DETAILS TO CLAIM YOUR PRIZE.
      </ThanksCopy>
      <ThanksCopy>
        REDIRECTING IN... {countdown}
      </ThanksCopy>
      <ThanksCharacterWrap>
        <img src={MarkHank} alt="Hank and Mark"/>
      </ThanksCharacterWrap>
    </>
  );
};

export default Thanks;
