import { useEffect } from 'react';

export const useScrollLock = (isLocked) => {

    const preventDefault = (e) => e.preventDefault();

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (isLocked) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', preventDefault, { passive: false });
    } else {
      document.body.style.overflow = originalStyle;
      document.removeEventListener('touchmove', preventDefault, { passive: false });
    }

    return () => {
      document.body.style.overflow = originalStyle;
      document.removeEventListener('touchmove', preventDefault, { passive: false });
    };
  }, [isLocked]);
};
