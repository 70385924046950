import styled from "styled-components";

export const ThanksTitleWrap = styled.div`
width:100%;
text-align:center;
margin-top:30px;
    img{
    width:280px;
    }
`
export const ThanksCopy = styled.div`
    width:280px;
    margin:20px auto 0 auto;
    text-align:center;
    font-family:Bailey;
    font-size:18px;
    color:white;
    font-weight:bold;
`

export const ThanksCharacterWrap = styled.div`
    width:100%;
    margin-top:25px;
    img{
        width:100%;
        max-width:500px;
        position:relative;
        top:4px;
    }
`
