import styled from "styled-components";

export const FormWrap = styled.div`
    width:100%;
    text-align:center;
    display:flex;
    justify-content:center;
    flex-direction:column;
    form{
        margin:auto;
    }
`

export const ShadowInput = styled.div`
position: absolute;
  top: 4px;
  left: -2px;
  width: 300px;
  height: 63px;
  border: 2px solid #2C2A28;
  z-index: -1;
  display: ${(props) => props.isActive ? 'none !important' : 'inline-block'};
`
export const InputWrapper = styled.div`
    position:relative;
    width:300px;
`
export const FormCharacterWrap = styled.div`
    width:100%;
    max-width:450px;
    margin-top:-40px;
    z-index:-1;
    position:relative;
    img{
    width:100%;
    max-width:450px;
    position:relative;
    top:4px;
    }
`
export const FormTitle = styled.div`
    margin-top:30px;
    img{
        width:300px;
    }
        margin-bottom:20px;
`
export const Disclaimer = styled.div`
    font-family:Arial;
    color:white;
    font-size:12px;
    text-align:center;
    margin-top:10px;
    margin-bottom:20px;
`