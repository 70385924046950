import axios from "axios";

export const getPrize = async () => {	
	try {
		const res = await axios.get('/prizewheel/get-prize');
		return res?.data;		
	} catch (e) { 
		return e?.response?.data ? e.response.data : e;
	}  
};

export const updateClaim = async (claim) => {	
	try {
		const res = await axios.put(`/claims/${claim.id}`, claim);
		return res?.data;		
	} catch (e) { 
		return e?.response?.data ? e.response.data : e;
	}  
};

export const sendGift = async (data) => {	
	try {
		const res = await axios.post('/giftogram/send-gift', data);
		return res?.data;		
	} catch (e) { 
		return e?.response?.data ? e.response.data : e;
	}  
};

export const checkEmail = async (email, amount) => {
	try {
	  const res = await axios.post('/claims/check-email', {
		email: email,
		amount: amount,
	  });
	  return res?.data;
	} catch (e) {
	  return e?.response?.data ? e.response.data : e;
	}
  };

  export const getStats = async () => {	
	try {
		const res = await axios.get('/eventday');
		return res?.data;		
	} catch (e) { 
		return e?.response?.data ? e.response.data : e;
	}  
};