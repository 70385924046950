import { BrowserRouter, Route, Routes } from "react-router-dom";
import BaseLayout from "./layouts/BaseLayout";
import Home from "./containers/Home";
import Error from "./containers/Error";
import ClaimForm from "./containers/ClaimForm";
import './App.css';
import axios from "axios";
import Thanks from "./containers/Thanks";
import Stats from "./containers/Stats";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<BaseLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/error" element={<Error />} />
          <Route path="/form" element={<ClaimForm />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/stats" element={<Stats />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
