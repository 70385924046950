import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SubmitButton from "../../assets/images/submit_button.png";
import { FormWrap, ShadowInput, InputWrapper, FormTitle, FormCharacterWrap, Disclaimer } from "./styled";
import FinishBiz from '../../assets/images/finish_biz.png';
import MarkHank from '../../assets/images/mark_and_hank.png';
import { updateClaim, sendGift, checkEmail } from "../../API";
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import SubmitButtonPressed from '../../assets/images/submit_button_pressed.png';

const ClaimForm = () => {
  const [activeField, setActiveField] = useState("");
  const [claimID, setClaimID] = useState(null);
  const [amount, setAmount] = useState(null);

  let location = useLocation();
  let navigate = useNavigate();

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  useEffect(() => {
    if(location.state && location.state.claimID && location.state.amount){
        setClaimID(location.state.claimID);
        setAmount(location.state.amount);
    }else{
      console.log('No claim id')
    }
}, [location]);

  const _onSubmit = async (values) =>{

    const giftData = {
      external_id: uuidv4(),
      denomination: `${amount}`,
      recipients: [
        {
          name: `${values.firstName} ${values.lastName}`,
          email: values.email,
        }
      ],
      campaign_id: process.env.REACT_APP_CAMPAIGN_ID,
      subject: "Here is your prize",
      claim_id: claimID
    }

    try {
      let res = await sendGift(giftData);
      if(res.data.order_id){
        const updateData = {
          id: claimID,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          status: 'claimed',
          order_id: res.data.order_id
        }

        try {
          let res = await updateClaim(updateData);
          if(res.success){
            navigate('/thanks');
          }
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const _checkEmail = async (values) =>{

    try {
      let res = await checkEmail(values.email, amount, claimID);
      if(!res.success){
        navigate('/error', { state: { type: 1 } });
      }else{
        _onSubmit(values);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
    <FormWrap>
      <FormTitle>
        <img src={FinishBiz} alt="Let's Finish Our Biz"/>
      </FormTitle>
      <Formik
        initialValues={{ firstName: "", lastName: "", email: "" }}
        validationSchema={validationSchema}
        onSubmit={_checkEmail}
      >
        {({ touched, errors, values, isSubmitting }) => (
          <Form autoComplete="off" noValidate>
            <InputWrapper>
              <ShadowInput isActive={activeField === 'firstName'} isError={errors.firstName && touched.firstName}/>
              <Field
                name="firstName"
                type="text"
                placeholder="First Name"
                className={`text_input ${errors.firstName && touched.firstName ? 'input-error' : ''}`}
                onFocus={() => setActiveField("firstName")}
                onBlur={() => setActiveField("")}
                autoComplete="first-name-field"
              />
            </InputWrapper>
            <InputWrapper>
            <ShadowInput isActive={activeField === 'lastName'} isError={errors.lastName && touched.lastName}/>
              <Field
                name="lastName"
                type="text"
                placeholder="Last Name"
                className={`text_input ${errors.lastName && touched.lastName ? 'input-error' : ''}`}
                onFocus={() => setActiveField("lastName")}
                onBlur={() => setActiveField("")}
                autoComplete="first-name-field"
              />
            </InputWrapper>
            <InputWrapper>
            <ShadowInput isActive={activeField === 'email'} isError={errors.email && touched.email}/>
              <Field
                name="email"
                type="email"
                placeholder="Email"
                className={`text_input ${errors.email && touched.email ? 'input-error' : ''}`}
                onFocus={() => setActiveField("email")}
                onBlur={() => setActiveField("")}
                autoComplete="first-name-field"
              />
            </InputWrapper>
            <Disclaimer>By clicking submit I agree to the terms and conditions.</Disclaimer>
            <button type="submit" className="submit_button" disabled={isSubmitting}>
              <img src={isSubmitting ? SubmitButtonPressed : SubmitButton} alt="submit" />
            </button>
          </Form>
        )}
      </Formik>
    </FormWrap>
    <FormCharacterWrap>
        <img src={MarkHank} alt="Mark and Hank" />
    </FormCharacterWrap>
    </>
  );
};

export default ClaimForm;
