import styled from "styled-components";

export const SpinHeader = styled.div`
    text-align:center;
    padding-top:20px;
    position:relative;
    z-index:2;
`
export const WheelWrapper = styled.div`
    position:relative;
    text-align:center;
    width:100%;
    top:-25px;
    z-index:1;
    .prize_wheel{
        width: 350px;
    }
`
export const TapWrapper = styled.div`
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    text-align:center;
    padding-top:10px;
    z-index:6;
    img{
    width:84px;
    }
`

export const CharacterWrap = styled.div`
    position:absolute;
    z-index:5;
    bottom:-130px;
    width:100%;
    text-align:center;
        img{
        width:100%;
        max-width:500px;
        }
`

export const LocationModalWrapper = styled.div`
    position:absolute;
    top:0;
    left:0;
    height:100vh;
    width:100vw;
    z-index:100;
    background-color:rgba(44,42,40, .8);
`
export const LocationWarning = styled.div`
    text-align:center;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index:200;
    img{
        width:324px;
    }
`
export const PrizeModal = styled.div`
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index:150;
    cursor:pointer;
    img{
        width:328px;
    }
`
export const PrizeModalWrapper = styled.div`
    position:absolute;
    top:0;
    left:0;
    height:100vh;
    width:100vw;
    z-index:100;
    background-color:rgba(44,42,40, .8);
`