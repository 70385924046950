import React, { useState, useEffect } from "react";
import { StatsWrapper, StatsTitle, DayTitle, StatGrid } from "./styled";
import { getStats } from "../../API";

const Stats = () => {

    const [stats, setStats] = useState(null);


    useEffect(() => {
       _getStats();
      }, []);


    const _getStats = async () =>{
    try {
        let res = await getStats();
        console.log(res);
        setStats(res);
      } catch (e) {
        console.log(e);
      }
    }

    const renderStatsForDay = (day) => {
        const dayStats = stats.find(stat => stat.day === day);
        return dayStats ? (
          <StatGrid>
            <p>Amount $5:<br />{dayStats.amount_5}</p>
            <p>Amount $30:<br /> {dayStats.amount_30}</p>
            <p>Amount $300:<br /> {dayStats.amount_300}</p>
          </StatGrid>
        ) : null;
      };

  return(
    <StatsWrapper>
    <StatsTitle>GIVE ME THOSE FRICKIN' NUMBERS</StatsTitle>
    <DayTitle>DAY 1</DayTitle>
    {stats&& renderStatsForDay("Day 1")}
    <DayTitle>DAY 2</DayTitle>
    {stats && renderStatsForDay("Day 2")}
    <DayTitle>DAY 3</DayTitle>
    {stats && renderStatsForDay("Day 3")}
  </StatsWrapper>
  )
};

export default Stats;
