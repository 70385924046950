import React, { useState, useEffect } from "react";
import {
  SpinHeader,
  WheelWrapper,
  TapWrapper,
  LocationModalWrapper,
  CharacterWrap,
  LocationWarning,
  PrizeModal,
  PrizeModalWrapper,
} from "./styled";
import SpinTitle from "../../assets/images/spin_that.png";
import SpintTitleX2 from "../../assets/images/spin_that.png";
import Wheel from "../../assets/images/wheel.png";
import SpinTap from "../../assets/images/spin_tap.png";
import HomeMark from '../../assets/images/home_mark.png';
import LocationCTA from "../../assets/images/location_warning.png";
import { useNavigate } from "react-router-dom";
import { getPrize } from "../../API";
import Prize300 from "../../assets/images/prize_300.png";
import Prize30 from "../../assets/images/prize_30.png";
import Prize5 from "../../assets/images/prize_5.png";
import { useScrollLock } from "../../helpers";

const Home = () => {
  const [spinning, setSpinning] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [prizeAmount, setPrizeAmount] = useState(0);
  const [claim, setClaim] = useState(null);
  const [isScrollLocked, setIsScrollLocked] = useState(false);
  const constantToken = '1db336b4-a718-4ead-a30d-23ac2681bbf5';

  useScrollLock(isScrollLocked);

  let navigate = useNavigate();

  const startSpinning = () => {

    const spun = localStorage.getItem('spun');
    if (spun === 'true') {
      navigate('/error', { state: { type: 1 } });
      return;
    }

    setSpinning(true);
    setTimeout(() => {
      _getPrize();
    }, 1000);
  };

  const XFINITY_LIVE_PHILADELPHIA = {
    latitude: 39.9045, // Replace with actual latitude from Google Maps
    longitude: -75.1688823, // Replace with actual longitude from Google Maps
    radius: 2 // Set this to the appropriate radius in kilometers
  };

  const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const distance = getDistanceFromLatLonInKm(
            latitude,
            longitude,
            XFINITY_LIVE_PHILADELPHIA.latitude,
            XFINITY_LIVE_PHILADELPHIA.longitude
          );

          if (distance > XFINITY_LIVE_PHILADELPHIA.radius) {
            console.log("not in area");
            redirectToError();
          }
        },
        (error) => {
          console.error("Error obtaining location", error);
          setShowLocationModal(true);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setShowLocationModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showLocationModal) {
      setIsScrollLocked(true);
    } else {
      setIsScrollLocked(false);
    }

    return () => {
      setIsScrollLocked(false);
    };
  }, [showLocationModal]);

  useEffect(() => {
    if(prizeAmount !== 0){
      setIsScrollLocked(true);
    }else{
      setIsScrollLocked(false);
    }
  }, [prizeAmount]);

  const redirectToError = () => {
    navigate('/error', { state: { type: 3 } });
  };

  const _getPrize = async () => {
    try {
      let res = await getPrize();
      console.log(res.claim);
      if(res.claim){
        setClaim(res.claim);
        setPrizeAmount(res.denomination);
        localStorage.setItem('spun', 'true');
        setSpinning(false);
        document.body.classList.add("modal-open");
      }else{
        navigate('/error', { state: { type: 2 } });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    return () => {
      setIsScrollLocked(false);
    };
  }, []);

  const goToForm = () => {
    setIsScrollLocked(false);
    navigate("/form", { state: { claimID: claim.id, amount: claim.amount } });
  };

  useEffect(() => {

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token === constantToken) {
      params.delete('token');
      navigate({ search: params.toString() }, { replace: true });
    } else {
      navigate('/error', { state: { type: 3 } });
    }
  }, [navigate]);

  return (
    <div>
      {showLocationModal && (
        <LocationModalWrapper>
          <LocationWarning>
            <img src={LocationCTA} alt="Please enable location services" />
          </LocationWarning>
        </LocationModalWrapper>
      )}
      {prizeAmount === 300 && (
        <PrizeModalWrapper>
          <PrizeModal onClick={goToForm}>
            <img src={Prize300} alt="Dude! You just won $300" />
          </PrizeModal>
        </PrizeModalWrapper>
      )}
      {prizeAmount === 30 && (
        <PrizeModalWrapper>
          <PrizeModal onClick={goToForm}>
            <img src={Prize30} alt="Dude! You just won $300" />
          </PrizeModal>
        </PrizeModalWrapper>
      )}
      {prizeAmount === 5 && (
        <PrizeModalWrapper>
          <PrizeModal onClick={goToForm}>
            <img src={Prize5} alt="Dude! You just won $300" />
          </PrizeModal>
        </PrizeModalWrapper>
      )}
      <SpinHeader>
        <img
          src={SpinTitle}
          srcSet={`${SpinTitle} 1x, ${SpintTitleX2} 2x`}
          alt="Yo, Spin that frickin' wheel."
        />
      </SpinHeader>
      <WheelWrapper>
        <TapWrapper onClick={startSpinning}>
          <img src={SpinTap} alt="Tap To Spin" />
        </TapWrapper>
        <img
          className={`prize_wheel ${spinning ? "spinning" : ""}`}
          src={Wheel}
          alt="Prize Wheel"
        />
        <CharacterWrap>
          <img src={HomeMark} alt="Mark" />
        </CharacterWrap>
      </WheelWrapper>
    </div>
  );
};

export default Home;
